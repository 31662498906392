.mainContainer {
  background-color: #dbceb4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4364px;
}

.priceTicks {
  color: black;
  font-size: 20px;
}

.title {
  font-size: 50px;
}
