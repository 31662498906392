import React from "react";
import classes from "./Services.module.css";
import { useGetAllTickersQuery } from "../../Features/polygonApi";

const ServicesPage = () => {
  return (
    <div className={classes.mainContainer}>
      <h1 className={classes.title}>Services Page under contruction...</h1>
    </div>
  );
};

export default ServicesPage;
