import React from "react";
import classes from "./AboutPage.module.css";

const AboutPage = () => {
  return (
    <div className={classes.mainContainer}>
      <div>
        <h1 className={classes.title}>About Page under contructions...</h1>
      </div>
    </div>
  );
};

export default AboutPage;
