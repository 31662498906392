.mainContainer {
  background-color: black;
  height: 550px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-left: 400px;
  padding-right: 400px;
}
.leftContainer {
  width: 50%;
  padding-right: 50px;
}
.rightContainer {
  width: 50%;
}
.image {
  border-radius: 15px;
  width: 80%;
  height: 80%;
}
.title {
  color: white;
  font-size: 50px;
  font-family: "Agency FB";
}
.text {
  color: white;
  font-size: 20px;
}

.button {
  color: #ffffff;
  font-family: "Familjen Grotesk", Helvetica;
  align-items: center;
  background-image: linear-gradient(to right, #a06d3a, #d74e79);
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 18px 32px;
  position: relative;
  height: 40px;
  width: 160px;
}
