.logo {
  width: 50px;
  height: 50px;
}

.xlogo {
  width: 40px;
  height: 40px;
}

.logotext {
  align-self: stretch;
  color: white;
  font-family: "Irish Grover", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.background {
  height: 75px;
  background-color: #a0733a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 80%;
  height: 60px;
  display: flex;
}
.leftcontainer {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middlecontainer {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightcontainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
