.mainContainer {
  background-color: #dbceb4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
}

.title {
  font-size: 50px;
}
